import { PartyRole } from '@paid-ui/enums/party';

import { BusinessStructureExtended, PartyType } from '../enums';
import {
  type BusinessContractParty,
  type IndividualContractParty,
  type PartyUserInfo,
} from '../types';

export const emptyUserInfo: PartyUserInfo = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  address: '',
  fax: '',
};

export const defaultIndividualsPartyInfo: IndividualContractParty = {
  id: null,
  role: '',
  inviteType: PartyRole.INVITER,
  payType: '',
  partyType: PartyType.INDIVIDUALS,
  userInfoId: null,
  additionalIndividuals: [],
  isKeyDetailsMismatch: false,
  roleOnContract: '',
  isCurrentParty: false,
  ...emptyUserInfo,
};

export const defaultBusinessPartyInfo: BusinessContractParty = {
  id: null,
  role: '',
  inviteType: PartyRole.INVITER,
  payType: '',
  partyType: PartyType.BUSINESS,
  businessInfoId: null,
  isKeyDetailsMismatch: false,
  isCurrentParty: false,
  roleOnContract: '',
  owner: {
    ...emptyUserInfo,
    userInfoId: null,
  },
  structure: BusinessStructureExtended.UNKNOWN,
  name: '',
  abn: '',
  acn: '',
  licenceNumber: '',
  address: '',
  contactNumber: '',
  fax: '',
};
