export enum SupercontractBrand {
  ABIC = 'ABIC',
  AIA = 'AIA',
  MBA = 'MBA',
  PAID = 'PAID',
  RBN = 'RBN',
}

export enum SupercontractCode {
  ABIC_HEAD_BWC_2018 = 'ABIC_HEAD_BWC_2018',
  ABIC_HEAD_CP_2014 = 'ABIC_HEAD_CP_2014',
  ABIC_HEAD_MW_2018 = 'ABIC_HEAD_MW_2018',
  ABIC_HEAD_SW_2018 = 'ABIC_HEAD_SW_2018',
  ABIC_SUB_BWC_2018 = 'ABIC_SUB_BWC_2018',
  ABIC_SUB_MW_2018 = 'ABIC_SUB_MW_2018',
  ABIC_SUB_SW_2018 = 'ABIC_SUB_SW_2018',
  MBA_DECON_HEAD_2024 = 'MBA_DECON_HEAD_2024',
  MBA_DECON_SUB_2024 = 'MBA_DECON_SUB_2024',
  RBN_HEAD_2024 = 'RBN_HEAD_2024',
  RBN_HIBC_HEAD_2024 = 'RBN_HIBC_HEAD_2024',
  PAID_NHR_HEAD_NSW_2024 = 'PAID_NHR_HEAD_NSW_2024',
  PAID_NHR_HEAD_QLD_2024 = 'PAID_NHR_HEAD_QLD_2024',
  PAID_NHR_HEAD_SA_2024 = 'PAID_NHR_HEAD_SA_2024',
  PAID_NHR_HEAD_TAS_2024 = 'PAID_NHR_HEAD_TAS_2024',
  PAID_NHR_HEAD_VIC_2024 = 'PAID_NHR_HEAD_VIC_2024',
  PAID_NHR_HEAD_WA_2024 = 'PAID_NHR_HEAD_WA_2024',
  PAID_SUB_2024 = 'PAID_SUB_2024',
  PAID_SUPPLY_2024 = 'PAID_SUPPLY_2024',
}

export enum ReferenceToTitleType {
  TORRENS_TITLE = 'TORRENS_TITLE',
  COMPUTER_FOLIO = 'COMPUTER_FOLIO',
  OLD_SYSTEM = 'OLD_SYSTEM',
}

export const ReferenceToTitleTypeLabel = {
  [ReferenceToTitleType.TORRENS_TITLE]: 'Torrens Title',
  [ReferenceToTitleType.COMPUTER_FOLIO]: 'Computer folio',
  [ReferenceToTitleType.OLD_SYSTEM]: 'Old system',
};

export enum SecurityType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}
