import { getConstructionDays } from '@paid-ui/utils/contract';
import dayjs from 'dayjs';
import { clamp } from 'lodash';

import { contractManager } from '../model';
import { type Contract } from '../types';

const formatDate = (date?: string) => (date ? dayjs(date) : null);

export const saveTimeline = (contract?: Contract) => {
  if (!contract) return;

  const {
    anticipatedCommencementDate,
    commencementDate,
    anticipatedCompletionDate: anticipatedCompletionDateFromApi,
    contractualCompletionDate: contractualCompletionDateFromApi,
    actualCompletionDate,
    initialConstructionDays,
    remainingDays: remainingDaysFromApi,
  } = contract;

  const constructionDays = getConstructionDays(contract);

  const anticipatedCompletionDate = anticipatedCompletionDateFromApi
    ? formatDate(anticipatedCompletionDateFromApi)
    : anticipatedCommencementDate
      ? dayjs(anticipatedCommencementDate).add(constructionDays, 'days')
      : null;
  const contractualCompletionDate = contractualCompletionDateFromApi
    ? formatDate(contractualCompletionDateFromApi)
    : commencementDate
      ? dayjs(commencementDate).add(constructionDays, 'days')
      : null;

  contractManager.hasWorkCommenced = Boolean(commencementDate);
  contractManager.hasWorkCompleted = Boolean(actualCompletionDate);
  contractManager.anticipatedCommencementDate = formatDate(anticipatedCommencementDate);
  contractManager.commencementDate = formatDate(commencementDate);
  contractManager.anticipatedCompletionDate = anticipatedCompletionDate;
  contractManager.contractualCompletionDate = contractualCompletionDate;
  contractManager.actualCompletionDate = formatDate(actualCompletionDate);
  contractManager.initialConstructionDays = initialConstructionDays ?? 0;
  contractManager.constructionDays =
    commencementDate && actualCompletionDate
      ? dayjs(actualCompletionDate).diff(dayjs(commencementDate), 'days')
      : constructionDays;

  if (!commencementDate) {
    contractManager.overdueDays =
      anticipatedCompletionDate?.diff(dayjs().startOf('day'), 'days') ?? 0;
  } else if (actualCompletionDate) {
    contractManager.overdueDays =
      contractualCompletionDate?.diff(actualCompletionDate, 'days') ?? 0;
  } else {
    contractManager.overdueDays =
      contractualCompletionDate?.diff(dayjs().startOf('day'), 'days') ?? 0;
  }

  const startDate = commencementDate ?? anticipatedCommencementDate;

  if (typeof remainingDaysFromApi === 'number') {
    contractManager.remainingDays = remainingDaysFromApi;
  } else if (!startDate) {
    contractManager.remainingDays = constructionDays;
  } else if (dayjs(startDate).isAfter(dayjs().endOf('day'))) {
    contractManager.remainingDays = constructionDays;
  } else {
    const pastDays = dayjs().startOf('day').diff(startDate, 'days') + 1;
    contractManager.remainingDays = clamp(constructionDays - pastDays, 0, constructionDays);
  }
};
