import { ProductReferenceCode } from '@paid-ui/enums/product';
import { SupercontractCode } from '@paid-ui/enums/supercontract';

export const contractTemplateReferenceCodeMap = {
  [SupercontractCode.ABIC_HEAD_BWC_2018]: ProductReferenceCode.ABIC_HEAD_BWC_2018,
  [SupercontractCode.ABIC_HEAD_CP_2014]: ProductReferenceCode.ABIC_HEAD_CP_2014,
  [SupercontractCode.ABIC_HEAD_MW_2018]: ProductReferenceCode.ABIC_HEAD_MW_2018,
  [SupercontractCode.ABIC_HEAD_SW_2018]: ProductReferenceCode.ABIC_HEAD_SW_2018,
  [SupercontractCode.ABIC_SUB_BWC_2018]: ProductReferenceCode.ABIC_SUB_BWC_2018,
  [SupercontractCode.ABIC_SUB_MW_2018]: ProductReferenceCode.ABIC_SUB_MW_2018,
  [SupercontractCode.ABIC_SUB_SW_2018]: ProductReferenceCode.ABIC_SUB_SW_2018,
  [SupercontractCode.MBA_DECON_HEAD_2024]: ProductReferenceCode.MBA_DECON_HEAD_2024,
  [SupercontractCode.MBA_DECON_SUB_2024]: ProductReferenceCode.MBA_DECON_SUB_2024,
  [SupercontractCode.RBN_HEAD_2024]: ProductReferenceCode.ONPLATFORM_PRIME_RBN_NEW_HOME,
  [SupercontractCode.RBN_HIBC_HEAD_2024]: ProductReferenceCode.ONPLATFORM_PRIME_RBN_IMPROVEMENT,
  [SupercontractCode.PAID_NHR_HEAD_NSW_2024]: ProductReferenceCode.PAID_NHR_HEAD_NSW_2024,
  [SupercontractCode.PAID_NHR_HEAD_QLD_2024]: ProductReferenceCode.PAID_NHR_HEAD_QLD_2024,
  [SupercontractCode.PAID_NHR_HEAD_SA_2024]: ProductReferenceCode.PAID_NHR_HEAD_SA_2024,
  [SupercontractCode.PAID_NHR_HEAD_TAS_2024]: ProductReferenceCode.PAID_NHR_HEAD_TAS_2024,
  [SupercontractCode.PAID_NHR_HEAD_VIC_2024]: ProductReferenceCode.PAID_NHR_HEAD_VIC_2024,
  [SupercontractCode.PAID_NHR_HEAD_WA_2024]: ProductReferenceCode.PAID_NHR_HEAD_WA_2024,
  [SupercontractCode.PAID_SUB_2024]: ProductReferenceCode.ONPLATFORM_SUB_PAID,
  [SupercontractCode.PAID_SUPPLY_2024]: ProductReferenceCode.ONPLATFORM_SUPPLY_PAID,
};
